import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import 'moment/locale/nl';
import moment from "moment";

const InputDayPicker = ({id, name, label, description, value, valid, onChange, error, className, styles, required, canChangeYear, disabled}) => {
    const currentDate = value && value.length !== 0 ? new Date(value[0].day) : new Date();
    const currentYear = currentDate.getFullYear();
    const fromMonth = new Date(currentYear - 100, 0);
    const toMonth = new Date(currentYear, 0);

    const [month, setMonth] = useState(currentDate.getMonth());
    const [year, setYear] = useState(currentDate.getFullYear());
    const [showMonth, setShowMonth] = useState(new Date(year, month));


    const renderDefaultHeading = date => {
        return <div className="DayPicker-Caption"><div>{moment(date).format('MMMM YYYY')}</div></div>
    }

    const renderCustomHeading = (date, localeUtils) => {
        const months = localeUtils.getMonths();

        const years = [];
        for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
            years.push(i);
        }

        return (
            <form className="DayPicker-Caption">
                <select name="month" onChange={handleChange} value={month}>
                    {months.map((m, i) => (
                        <option key={m} value={i}>{m}</option>
                    ))}
                </select>
                <select name="year" onChange={handleChange} value={year}>
                    {years.map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </form>
        );
    }

    const handleChange = function handleChange(e) {
        const { year, month } = e.target.form;
        setMonth(month.value);
        setYear(year.value);
        setShowMonth(new Date(year.value, month.value));
    };

    return (
        <div className={`form-group${className ? ` ${className}` : ''}`} style={styles}>
            {label && <div><label htmlFor={id}>{label} {required ? <code>*</code> : null}</label></div>}

            <DayPicker localeUtils={MomentLocaleUtils}
                       locale="nl"
                       modifiers={{weekend: { daysOfWeek: [0, 6] }}}
                       selectedDays={value ? value.map(({day}) => new Date(day)) : null}
                       onDayClick={(date, modifiers) => {
                           onChange({target: {name}}, date, modifiers)
                       }}
                       month={showMonth}
                       disabledDays={disabled}
                       captionElement={({date, localeUtils}) => canChangeYear ? renderCustomHeading(date, localeUtils) : renderDefaultHeading(date)}
            />

            <div className="description">{description}</div>
            <small className="invalid-feedback">{error}</small>
        </div>
    );
};


InputDayPicker.defaultProps = {
    label: '',
    placeholder: '',
    value: '',
    error: '',
    valid: true,
    className: '',
    styles: {},
    required: false,
    disabled: null,
    canChangeYear: false,
};


InputDayPicker.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.array,
    error: PropTypes.string,
    valid: PropTypes.bool,
    className: PropTypes.string,
    styles: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    canChangeYear: PropTypes.bool
};


export default InputDayPicker;