import React from "react";
import {Link, NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {faIdBadge, faIdCard} from "@fortawesome/free-regular-svg-icons";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../../../core/store/authentication/auth.actions";

const MainNavigation = () => {
    const dispatch = useDispatch();

    return (
        <ul className="navbar">
            <li className="navbar-item">
                <NavLink to="/opleidingen"
                         className={({isActive}) => `navbar-link${isActive ? ' active' : ''}`}
                >
                    <FontAwesomeIcon icon={faHome}
                                     className={"icon"}
                                     fixedWidth
                    />
                    <span>Home</span>
                </NavLink>
            </li>

            <li className="navbar-item">
                <NavLink to="/profiel"
                         className={({isActive}) => `navbar-link${isActive ? ' active' : ''}`}
                >
                    <FontAwesomeIcon icon={faIdCard}
                                     className={"icon"}
                                     fixedWidth
                    />
                    <span>Profiel</span>
                </NavLink>
            </li>

            <li className="navbar-item">
                <NavLink to="/account"
                         className={({isActive}) => `navbar-link${isActive ? ' active' : ''}`}
                >
                    <FontAwesomeIcon icon={faIdBadge}
                                     className={"icon"}
                                     fixedWidth
                    />
                    <span>Account</span>
                </NavLink>
            </li>

            <li className="navbar-item">
                <Link to={"#"}
                      onClick={() => dispatch(logoutUser())}
                      className="navbar-link">
                    <FontAwesomeIcon icon={faSignOutAlt}
                                     className={"icon"}
                                     fixedWidth
                    />
                    <span>Uitloggen</span>
                </Link>
            </li>
        </ul>
    )
};


export default MainNavigation;
