import {INPUT_TYPES} from "../../../helpers/input.types.helper";

export const AuthForgotPassFormData = {
    format: [{
        type: INPUT_TYPES.GROUP,
        name: 'group',
        direction: 'vertical',
        fields: ['email']
    }],
    fields: [{
        type: INPUT_TYPES.EMAIL,
        name: 'email',
        label: "E-mailadres",
        placeholder: "",
        description: null,
        required: true,
    }]
}