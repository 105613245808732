import React, {useEffect, useRef, useState} from "react";
import Button from "../Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {loadToppings} from "../../core/store/topping/topping.actions";
import Form from "./SubscriptionComponents/Form";
import Modal from "../Modal/Modal";

const Subscription = ({obj, objType}) => {
    const dispatch = useDispatch();
    const formRef = useRef();

    const {toppings, toppingsLoading} = useSelector(state => state.toppingReducer);
    const [showModal, setShowModal] = useState(false);
    const [formValid, setFormValid] = useState();

    const handleSubmit = () => {
        formRef.current.handleSubmit();
        setShowModal(false);
    }

    useEffect(() => {
        dispatch(loadToppings());
    }, [dispatch]);

    return (
        <div className="btn-group full-flex">
            <Button type="button"
                    buttonStyle="primary"
                    buttonSize="small"
                    label={toppingsLoading ? 'Laden' : 'Inschrijven'}
                    disabled={toppingsLoading}
                    className={`${(obj.order ? "btn-success" : "btn-danger")}${toppingsLoading ? ' btn-loading' : ''}`}
                    onClick={() => setShowModal(true)}
            />

            {!toppingsLoading &&
            <Modal show={showModal}
                   close={() => setShowModal(false)}
                   title={"temp"}
                   large={true}
                   actions={[{
                       label: "Verstuur formulier",
                       action: handleSubmit,
                       disabled: formValid
                   }]}
            >
                <Form ref={formRef}
                      toppings={toppings}
                      setFormValid={setFormValid}
                      obj={obj}
                      objType={objType}
                />
            </Modal>}
        </div>
    );
}

export default Subscription;