import PropTypes from "prop-types";
import "./EmptyComponent.scss";
import BackButton from "../BackButton/BackButton";

const EmptyComponent = ({content, goBack}) => {
    return (
        <>
            <div className="no-content">
                {content}
            </div>

            {goBack && <BackButton/>}
        </>
    );
}

EmptyComponent.defaultValues = {
    goBack: false
}

EmptyComponent.propTypes = {
    content: PropTypes.string.isRequired,
    goBack: PropTypes.bool,
};

export default EmptyComponent;