import {array, string} from "prop-types";

const PillsContent = ({selectedTab, children}) => {
    return children.filter(child => child.props.name === selectedTab)
}

PillsContent.propTypes = {
    selectedTab: string.isRequired,
    children: array.isRequired
}

export default PillsContent;