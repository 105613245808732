import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadTrainings} from "../../../core/store/training/training.actions";
import Loading from "../../../components/Loading/Loading";
import EmptyComponent from "../../../components/EmptyComponent/EmptyComponent";
import ItemOverview from "../../../components/ItemOverview/ItemOverview";
import {Col, Row} from "../../../components/Grid/Grid";

const TrainingList = () => {
    const dispatch = useDispatch();
    const {trainings, trainingsLoading} = useSelector(state => state.trainingReducer);

    useEffect(() => {
        dispatch(loadTrainings());
    }, [dispatch]);

    return (
        <Row>

                {trainingsLoading
                    ? <Loading/>
                    : (
                        trainings.length === 0
                            ? <EmptyComponent content="Geen opleidingen"/>
                            : trainings.map(training => (
                                <Col lg={6} md={6} sm={12} key={training.id}>
                                    <ItemOverview name={training.name}
                                                  slug={training.slug}
                                                  location={training.location}
                                                  days={training.days}
                                                  logo={training.logo}
                                    />
                                </Col>
                            ))
                    )}
        </Row>
    );
}

export default TrainingList;