import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdjust, faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import {func, string} from "prop-types";

import "./Toggle.scss";


const Toggle = ({mode, toggleTheme}) => {
    return (
        <button onClick={toggleTheme} className="theme-toggle" data-mode={mode}>
            <FontAwesomeIcon icon={faSun}/>
            <FontAwesomeIcon icon={faMoon}/>
            <FontAwesomeIcon icon={faAdjust}/>
        </button>
    );
};

Toggle.propTypes = {
    mode: string.isRequired,
    toggleTheme: func.isRequired
}

export default Toggle;