import "./Loading.scss";
import {bool, string} from "prop-types";

const Loading = ({className, showPatience}) => {
    return (
        <div className={`loading${className ? ` ${className}` : ''} ${showPatience ? 'wide' : 'custom'}`}>
            <svg viewBox="-4 1 145 140"><title>up-up-group-logotype-grijs</title>
                <path d="M0,16.63,32.81,49.41v92.32H31.63A31.63,31.63,0,0,1,0,110.1Z" fill="none"/>
                <path d="M136.85,126.92,104,94.11V0h1.18a31.63,31.63,0,0,1,31.63,31.63Z" fill="none"/>
                <path d="M84.83,74.85V6.25L84.8,0A32.78,32.78,0,0,0,52,32.78v9.31Z" fill="none"/>
                <path d="M52,68.63v73.1h0a32.81,32.81,0,0,0,32.81-32.81v-7.47Z" fill="none"/>
            </svg>

            {showPatience && <div className={"ellipsis"}>Even geduld</div>}
        </div>
    );
}

Loading.propTypes = {
    className: string,
    showPatience: bool
}

export default Loading;