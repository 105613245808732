import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {loginUser} from "../../../core/store/authentication/auth.actions";
import {Col, Container, Row} from "../../../components/Grid/Grid";
import Button from "../../../components/Button/Button";
import Toasts from "../../../components/Snackbar/Snackbar";
import {AuthLoginFormData} from "../../../core/services/data/form/auth.login.form.data";
import Form from "../../../components/Form/Form";
import logo from '../../../assets/images/logo-up.png';
import './Login.scss';
import {formExtractValuesHelpers} from "../../../core/helpers/form.helpers";

const Login = () => {
    const dispatch = useDispatch();
    const [form, setForm] = useState(AuthLoginFormData);

    const login = () => {
        const values = formExtractValuesHelpers(form);
        dispatch(loginUser(values));
    }

    return (
        <Container>
            <Row style={{height: '100vh', paddingTop: 0}}>
                <Col xl={7} lg={8} md={9} sm={10} xs={11} className="login">
                    <img src={logo} alt="UniPartners, united around values" className="login__logo"/>

                    <Form form={form}
                          updateForm={setForm}
                    />

                    <div className="btn-group" style={{marginTop: 20}}>
                        <Button type="submit"
                                label="Login"
                                buttonStyle="primary"
                                onClick={login}
                                disabled={!form.valid}
                        />

                        <Link to={"/wachtwoord-vergeten"}
                              className="btn btn-link"
                              style={{padding: '8px 25px'}}
                        >
                            Wachtwoord vergeten?
                        </Link>
                    </div>
                </Col>
            </Row>

            <Toasts/>
        </Container>
    );
}

export default Login;