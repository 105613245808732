import {INPUT_TYPES} from "./input.types.helper";

export class FieldValidator {
    static pattern = new RegExp('^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$', 'i');

    static telephonePattern = new RegExp(/^(\+?32|0)4\d{8}$/);
    static passwordPattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);

    static isUrl = (field, value) => {
        const valid = value === '' || !!this.pattern.test(value);

        return {
            valid,
            error: !valid ? `${field.label ? field.label : 'Dit veld'} is een ongeldige url.` : '',
        }
    }

    static isTelephone = (field, value) => {
        const valid = value === '' || !!this.telephonePattern.test(value);

        return {
            valid,
            error: !valid ? `${field.label ? field.label : 'Dit veld'} is een ongeldige telefoonnummer.` : '',
        }
    }

    static isPassword = (field, value) => {
        const valid = value === '' || !field.checkRequirements || !!this.passwordPattern.test(value);

        return {
            valid,
            error: !valid ? `${field.label ? field.label : 'Dit veld'} voldoet niet aan de vereisten van een wachtwoord.` : '',
        }
    }

    static isEmpty = (field, value) => {
        let valid = !(field.required && value.length <= 0);
        if (field.type === 'checkbox' || field.type === 'radio')
            valid = field.required ? (!!value) : true;
        else if (field.type === 'dropzone')
            valid = !(field.required && field.files.length <= 0);

        return {
            valid,
            error: !valid ? `${field.label ? field.label : 'Dit veld'} is een verplicht veld.` : '',
        }
    }

    static isEmail = (field, value) => {
        const valid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        return {
            valid,
            error: !valid ? `${field.label ? field.label : 'Dit veld'} is ongelig.` : ''
        };
    }

    static isNumber = (field, value) => {
        const minValid = field.min || field.min === 0 ? value >= field.min : true;
        const maxValid = field.max || field.max === 0 ? value <= field.max : true;
        const decimalsValid = field.step
            ? (Number.isInteger(field.step)
                ? Number.isInteger(Number(value))
                : function(val) {
                    const decimals = field.step.toString().split(".")[1].length;
                    const valDecimals = val.toString().split(".");
                    return val
                        ? (Math.floor(val) === Number(val) ? true : valDecimals[1].length <= decimals)
                        : true;
                }(value))
            : true;

        const valid = minValid && maxValid && decimalsValid;

        return {
            valid,
            error: !valid ? `${field.label ? field.label : 'Dit veld'} moet een getal${field.min || field.min === 0 ? ` groter dan ${field.min}` : ''}${field.max || field.max === 0 ? `${field.min || field.min === 0 ? ' en' : ''} kleiner dan ${field.max}` : ''} zijn${field.step ? ` ${function() { return Math.floor(field.step) === Number(field.step) ? 'zonder' : `met ${field.step.toString().split(".")[1].length}`}()} decimalen` : ''}.` : ''
        };
    }

    static validate(field, value = null) {
        value = value !== null ? value : (field.value ?? '');
        let validation = this.isEmpty(field, value);

        if (validation.valid) {
            switch (field.type) {
                case 'email':
                    validation = this.isEmail(field, value);
                    break;

                case 'number':
                    validation = this.isNumber(field, value);
                    break;

                case 'url':
                    validation = this.isUrl(field, value);
                    break;

                case 'telephone':
                    validation = this.isTelephone(field, value);
                    break;

                case 'password':
                    validation = this.isPassword(field, value);
                    break;

                default: break;
            }
        }

        return validation;
    }
}

export class FormValidator {
    /*static validateTabs = (form) => {
        const skipped = form.tabSettings.skipped;
        const tabsToSkip = form.format.filter(x => skipped.includes(x.name));
        const fieldToSkip = tabsToSkip.map(tab => tab.fields.map(x => x)).flat(7)

        return !form.values.filter(x => !fieldToSkip.includes(x.name)).find(x => x.valid !== true) && form.tabSettings.nextIndex === -1;
    }*/

    static validateFields = (form) => {
        return !form.fields.find(field => field.valid !== true);
    }

    static validate = (form) => {
        //let {format} = form;
        /*if (format && format[0] && format[0].type === "tab") {
            return this.validateTabs(form)
        }*/

        return this.validateFields(form)
    }
}

export const getDefaultValue = (field) => {
    switch (field.type) {
        case 'checkbox':
            return field.checked ?? false;
        case 'datepicker':
        case 'select':
        case 'creatable':
            return field.value ? field.value : [];
        default:
            return field.value ? field.value : "";
    }
}

export const formExtractValuesHelpers = (form) => {
    let object = {};

    form.fields.map(field => (
        object[field.name] = addValue(field)
    ));

    return object;
}

const addValue = (value) => {
    if (value.multiple) {
        if (value.files) {
            return value.files;
        } else {
            return value.value.map(x => x.hasOwnProperty('value') ? x.value : x);
        }
    } else {
        return value.value.hasOwnProperty('value') ? value.value.value : value.value;
    }
}