import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            border: `1px solid ${data.type === 'coce' ? 'rgba(171,24,45, .4)' : 'rgb(157,150,148, .4)'}`,
        }}
};

const InputSelect = ({id, options, label, placeholder, description, value, valid, onChange, onBlur, error, className, styles, isMulti, isClearable, isFixed, required, readOnly, autocomplete, disabled}) => {
    return (
        <div className={`form-group${className ? ` ${className}` : ''}`} style={styles}>
            {label && <label htmlFor={id}>{label} {required && <code>*</code>}</label>}

            <Select value={value}
                    options={options}
                    className={`select${(valid === '' ? '' : ( valid ? ' is-valid' : ' is-invalid'))}`}
                    classNamePrefix="select"
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    isMulti={isMulti ? isMulti : false}
                    isClearable={isClearable ? isClearable : false}
                    styles={{menuPortal: provided => ({...provided, zIndex: 999999}), ...colourStyles}}
                    menuPortalTarget={document.body}
                    menuPosition={isFixed ? 'fixed' : 'absolute'}
            />
            <div className="description">{description}</div>
            <small className="invalid-feedback">{error}</small>
        </div>
    );
}

InputSelect.defaultProps = {
    label: '',
    placeholder: '',
    value: '',
    error: '',
    valid: true,
    className: '',
    styles: {},
    required: false,
    readOnly: false,
    autocomplete: false,
    disabled: false,
    isMulti: false,
    isClearable: false,
    isFixed: false
};


InputSelect.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.object,
    error: PropTypes.string,
    valid: PropTypes.bool,
    className: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    styles: PropTypes.object,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    autocomplete: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isFixed: PropTypes.bool,
};

export default InputSelect;