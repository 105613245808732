import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import React, {useState} from "react";
import {authActivateFormData} from "../../../core/services/data/form/auth.activate.form.data";
import {Row} from "../../../components/Grid/Row/Row";
import {Col} from "../../../components/Grid/Col/Col";
import logo from "../../../assets/images/logo-up.png";
import Form from "../../../components/Form/Form";
import Button from "../../../components/Button/Button";
import Toasts from "../../../components/Snackbar/Snackbar";
import {Container} from "../../../components/Grid/Container/Container";
import {formExtractValuesHelpers} from "../../../core/helpers/form.helpers";
import {activateUser} from "../../../core/store/authentication/auth.actions";

const Activate = () => {
    const dispatch = useDispatch();
    const qString = useLocation().search;
    const email = new URLSearchParams(qString).get('email');
    const token = new URLSearchParams(qString).get('ref');

    const [form, setForm] = useState(authActivateFormData(email));

    const activate = () => {
        const values = formExtractValuesHelpers(form);
        dispatch(activateUser({token, ...values}));
    }

    return (
        <Container>
            <Row style={{height: '100vh', paddingTop: 0}}>
                <Col xl={7} lg={8} md={9} sm={10} xs={11} className="login">
                    <img src={logo} alt="UniPartners, united around values" className="login__logo"/>

                    {token && email
                        ? (
                            <>
                                <Form form={form}
                                      updateForm={setForm}
                                />

                                <div className="btn-group" style={{marginTop: 20}}>
                                    <Button type="submit"
                                            label="Login"
                                            buttonStyle="primary"
                                            onClick={activate}
                                            disabled={!form.valid}
                                    />
                                </div>
                            </>
                        )
                        : <div className="empty-list" style={{color: "#9D9694"}}>Je gegevens zijn niet correct<br />Volg de link die je van ons kreeg.</div>}
                </Col>
            </Row>

            <Toasts/>
        </Container>
    );
}

export default Activate;