import {INPUT_TYPES} from "../../../helpers/input.types.helper";

export const AuthLoginFormData = {
    format: [{
        type: INPUT_TYPES.GROUP,
        name: 'group',
        direction: 'vertical',
        fields: ['email', 'password']
    }],
    fields: [{
        type: INPUT_TYPES.EMAIL,
        name: 'email',
        label: "E-mailadres",
        placeholder: "",
        description: null,
        required: true,
    }, {
        type: INPUT_TYPES.PASSWORD,
        name: 'password',
        label: "Wachtwoord",
        placeholder: "",
        description: null,
        required: true,
        checkRequirements: false,
    }]
}