import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Col, Row} from "../../Grid/Grid";
import InputCheckbox from "../../Form/formInput/InputCheckbox";
import {toLongDate} from "../../../core/helpers/date.helpers";
import InputTextarea from "../../Form/formInput/InputTextarea";
import {InputSelect} from "../../Form/formInput";
import {orderData} from "../../../core/services/data/order.data";
import {useDispatch, useSelector} from "react-redux";
import {sendForm} from "../../../core/store/training/training.actions";

const Form = forwardRef(({obj, objType, toppings, setFormValid}, ref) => {
    const dispatch = useDispatch();
    const [order, setOrder] = useState(() => orderData(obj.days, obj.order, toppings));
    const {user} = useSelector(state => state.authReducer);

    const handleOrderCheckbox = (date, name) => {
        setOrder({
            ...order,
            days: order.days.map(x => {
                if (x.date !== date) return x;

                return {
                    ...x,
                    [name]: !x[name],
                    sizeValid: x.size !== null && x.size !== undefined ? true : x.order,
                    toppingValid: x.topping !== null && x.topping !== undefined ? true : x.order
                }
            })
        });
    }

    const handleOrderTextarea = (e) => {
        const {value, name} = e.target;
        setOrder({
            ...order,
            [name]: value
        });
    }

    const handleOrderTouched = (date, name) => {
        setOrder({
            ...order,
            days: order.days.map(x => {
                if (x.date !== date) return x;

                return {
                    ...x,
                    [`${name}Touched`]: true
                }
            })
        });
    }

    const handleOrderSelect = (selectedOption, date, name) => {
        setOrder({
            ...order,
            days: order.days.map(x => {
                if (x.date !== date) return x;

                return {
                    ...x,
                    [name]: selectedOption,
                    [`${name}Valid`]: selectedOption !== null && selectedOption !== undefined,
                    extra: selectedOption && selectedOption.extra ? x.extra : false
                }
            })
        });
    }

    const validateForm = () => {
        const invalid = order.days.filter(x => x.sizeValid === false || x.toppingValid === false);
        setFormValid(invalid.length === 0);
    }

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            const data = new FormData();
            data.set("userId", user.id);
            data.set("trainingId", obj.id);
            data.set('remark', order.remark);

            for (const day of order.days.filter(x => x.acceptOrders)) {
                data.append(
                    'results[]',
                    day.order
                        ? JSON.stringify({date: day.date, size: day.size.value, topping: day.topping.value, extra: day.topping.extra ? day.extra : false})
                        : JSON.stringify({date: day.date, size: null, topping: null, extra: null})
                )
            }

            dispatch(sendForm(objType, obj.id, data, order));
        }
    }));

    useEffect(() => {
        validateForm();
    });

    return (
        <Row>
            {order &&
            <>
                {order.days.map((day, i) => {
                    if (day.acceptOrders) {
                        return (
                            <Col lg={3} md={4} sm={6} key={i}>
                                <p><b>{toLongDate(day.date)}</b></p>
                                <InputCheckbox id={`order_${i}`}
                                               name={`order_${i}`}
                                               checked={day.order}
                                               label="Bestel broodje"
                                               onChange={() => handleOrderCheckbox(day.date, 'order')}
                                />
                                {day.order &&
                                <>
                                    <InputSelect id={`size_${i}`}
                                                 options={[
                                                     {value: "small_white", label: "Klein wit broodje"},
                                                     {value: "large_white", label: "Groot wit broodje"},
                                                     {value: "small_dark", label: "Klein bruin broodje"},
                                                     {value: "large_dark", label: "Groot bruin broodje"}
                                                 ]}
                                                 onChange={(e) => handleOrderSelect(e, day.date, 'size')}
                                                 value={day.size}
                                                 label="Broodje"
                                                 placeholder="Selecteer je broodje"
                                                 required={true}
                                                 isMulti={false}
                                                 error="Broodje is een verplicht veld"
                                                 valid={day.sizeTouched ? day.sizeValid : true}
                                                 onBlur={() => handleOrderTouched(day.date, 'size')}
                                    />
                                    <InputSelect id={`topping_${i}`}
                                                 options={toppings.map(x => {
                                                     return {value: x.id, label: x.topping, extra: x.hasOptions, desc: x.description}
                                                 })}
                                                 onChange={(e) => handleOrderSelect(e, day.date, 'topping')}
                                                 value={day.topping}
                                                 label="Beleg"
                                                 placeholder="Selecteer je beleg"
                                                 description={day.topping ? day.topping.desc : null}
                                                 required={true}
                                                 isMulti={false}
                                                 error="Beleg is een verplicht veld"
                                                 valid={day.toppingTouched ? day.toppingValid : true}
                                                 onBlur={() => handleOrderTouched(day.date, 'topping')}
                                    />

                                    {day.topping && day.topping.extra &&
                                    <InputCheckbox id={`extra_${i}`}
                                                   name={`extra_${i}`}
                                                   checked={day.extra}
                                                   label="Smos"
                                                   onChange={() => handleOrderCheckbox(day.date, 'extra')}
                                    />}
                                </>}
                            </Col>
                        )
                    } else {
                        return (
                            <Col lg={3} md={4} sm={6} key={i}>
                                <p><b>{toLongDate(day.date)}</b></p>
                                <p>{day.reason}</p>
                            </Col>
                        )
                    }
                })}

                <Col md={12}>
                    <InputTextarea id="remark"
                                   name="remark"
                                   onChange={handleOrderTextarea}
                                   value={order.remark}
                                   label="Opmerking"
                                   placeholder="Geef hier je speciale wensen of opmerkingen door"
                                   required={false}
                                   valid={true}
                                   rows={5}
                    />
                </Col>
            </>
            }
        </Row>
    );
});

export default Form;