export const typeHelper = (type, ext = false) => {
    switch (type) {
        case 'notifications': return `${ext ? 'De ' : ''}Notificaties`;
        case 'notification': return `${ext ? 'De ' : ''}Notificatie`;
        case 'articles': return `${ext ? 'De ' : ''}Artikelen`;
        case 'article': return `${ext ? 'Het ' : ''}Artikel`;
        case 'attachments': return `${ext ? 'De ' : ''}Bijlagen`;
        case 'attachment': return `${ext ? 'De ' : ''}Bijlage`;
        case 'trainings': return `${ext ? 'De ' : ''}Opleidingen`;
        case 'training': return `${ext ? 'De ' : ''}Opleiding`;
        case 'tags': return `${ext ? 'De ' : ''}Tags`;
        case 'tag': return `${ext ? 'De ' : ''}Tag`;
        case 'images': return `${ext ? 'De ' : ''}Afbeeldingen`;
        case 'image': return `${ext ? 'De ' : ''}Afbeelding`;
        case 'questions': return `${ext ? 'De ' : ''}vragen`;
        case 'toppings': return `${ext ? 'Het ' : ''}beleg`;
        case 'form': return `${ext ? 'Het ' : ''}formulier`;
        default: return `dit`;
    }
}