import React from 'react';
import PropTypes from "prop-types";

const InputCheckbox = ({id, name, label, description, value, checked, valid, onChange, onClick, onBlur, error, className, styles, required, readOnly, disabled}) => (
    <div className={'form-group'} style={styles}>
        <div className={`checkbox${className ? ` ${className}` : ''}${valid === '' ? '' : (valid ? ' is-valid' : ' is-invalid')}`}>
            <input type={"checkbox"}
                   name={name}
                   id={id}
                   value={value}
                   checked={checked}
                   onChange={onChange}
                   onClick={onClick}
                   onBlur={onBlur}
                   disabled={disabled}
                   readOnly={readOnly}
            />

            <label htmlFor={id}>{label} {required ? <code>*</code> : null}</label>
        </div>
        <div className="description">{description}</div>
        <small className="invalid-feedback">{error}</small>
    </div>
);


InputCheckbox.defaultProps = {
    label: '',
    value: '1',
    checked: false,
    error: '',
    valid: true,
    className: '',
    styles: {},
    required: false,
    readOnly: false,
    disabled: false,
};


InputCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    error: PropTypes.string,
    valid: PropTypes.bool,
    className: PropTypes.string,
    styles: PropTypes.object,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onBlur: PropTypes.func
};


export default InputCheckbox;