import {Col, Row} from "../../../../components/Grid/Grid";
import {InputField} from "../../../../components/Form/formInput";
import {useState} from "react";
import InputDayPicker from "../../../../components/Form/formInput/InputDayPicker";
import Button from "../../../../components/Button/Button";

const Personal = ({user, userFunction, userBirthday, submit}) => {
    const [func, setFunc] = useState(userFunction);
    const [birthday, setBirthday] = useState(userBirthday ? [{day: new Date(userBirthday * 1000)}] : null);

    const changeDate = (e, day, {selected, disabled}) => {
        if (!disabled) {
            if (selected) {
                setBirthday(null)
            } else {
                setBirthday([{day}])
            }
        }
    }

    const cancelForm = () => {
        setFunc(userFunction);
        setBirthday(userBirthday);
    }

    const handleSubmit = () => {
        submit({
            function: func,
            birthday: birthday ? birthday[0].day : null
        });
    }

    return (
        <>
            <h1>{`${user.firstName} ${user.lastName}`}<br /><div>Persoonsgegevens</div></h1>

            <Row>
                <Col md={6}>
                    <InputDayPicker name="birthday"
                                    id="birthday"
                                    label="Geboortedatum"
                                    value={birthday}
                                    description="Enkel de datum zal getoond worden op je profiel. Het geboortejaar of je leetijd worden niet vermeld. Klik op de geselecteerde datum om te verwijderen."
                                    required={false}
                                    valid={true}
                                    error=""
                                    onChange={changeDate}
                                    canChangeYear={true}
                    />
                </Col>

                <Col md={6}>
                    <InputField type="text"
                                name="function"
                                id="function"
                                label="Functie"
                                value={func}
                                required={false}
                                valid={true}
                                error=""
                                onChange={(e) => setFunc(e.target.value)}
                    />
                </Col>

                <Col md={12} className="right">
                    <div className="btn-group right">
                        <Button buttonStyle="secondary"
                                label="Annuleren"
                                onClick={cancelForm}
                        />
                        <Button buttonStyle="primary"
                                label="Bewaren"
                                onClick={handleSubmit}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Personal;