import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChalkboardTeacher, faMapPin, faUserFriends} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {toLongDate, toTime} from "../../core/helpers/date.helpers";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import "./ItemOverview.scss";

const ItemOverview = ({slug, name, location, days, instructor, organisers, logo}) => {
    return (
        <div className={"overview-item"}>
            {(logo || name) &&
            <div className={"header"}>
                {logo && <img src={logo} alt={name}/>}
                {name &&
                <h2 className="title">
                    {name}
                </h2>
                }
            </div>
            }

            <div className="content">
                {days.length > 0 &&
                (days.length === 1
                        ? DateTime(days[0])
                        : DateTimeArray(days)
                )}

                {organisers && organisers.length > 0 &&
                <div className={"organisers"}>
                    <FontAwesomeIcon icon={faUserFriends}/>
                    <ul className={"organiser"}>{organisers.map((organiser, i) => <li key={i}>{`${organiser.firstName} ${organiser.lastName}`}</li>)}</ul>
                </div>}

                {instructor && <div className={"instructor"}><FontAwesomeIcon icon={faChalkboardTeacher}/> {`${instructor.firstName} ${instructor.lastName}`}</div>}
                {location && <div className={"location"}><FontAwesomeIcon icon={faMapPin}/> <span dangerouslySetInnerHTML={{__html: location}}/></div>}

                <div className={`btn-group`}>
                    {slug && <Link to={`/opleidingen/${slug}`} className={`btn btn-primary btn-small single`}>Bekijk details</Link>}
                </div>
            </div>
        </div>
    );
}

const DateTime = ({startDate, endDate}) => {

    return (
        <div>
            <div className={"date"}>{toLongDate(startDate)}</div>
            <div className={"time"}><FontAwesomeIcon icon={faClock}/> {toTime(startDate)} {endDate && endDate !== startDate && ' - ' + toTime(endDate)}</div>
        </div>
    )
};

const DateTimeArray = (days) => {
    return (
        <div>
            {days.map((day, i) => {
                const {startDate, endDate} = day;

                return (
                    <div key={i} className={"date-array"}>
                        <div className={"date"}>{toLongDate(startDate)}</div>
                        <div className={"time"}><FontAwesomeIcon icon={faClock}/> {toTime(startDate)} {endDate && endDate !== startDate && ' - ' + toTime(endDate)}</div>
                    </div>
                )
            })}
        </div>
    )
};

ItemOverview.defaultValues = {
    days: []
}

ItemOverview.propTypes = {
    slug: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    days: PropTypes.array,
    instructor: PropTypes.object,
    organisers: PropTypes.array,
    logo: PropTypes.string
}

export default ItemOverview