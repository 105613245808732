import {INPUT_TYPES} from "../../../helpers/input.types.helper";

export const authActivateFormData = (email) => {
    return {
        format: [{
            type: INPUT_TYPES.GROUP,
            name: 'group',
            direction: 'vertical',
            fields: ['email', 'password']
        }],
        fields: [{
            type: INPUT_TYPES.EMAIL,
            name: 'email',
            label: "E-mailadres",
            placeholder: "",
            description: null,
            required: true,
            value: email,
            readOnly: true
        }, {
            type: INPUT_TYPES.PASSWORD,
            name: 'password',
            label: "Wachtwoord",
            placeholder: "",
            description: "Het wachtwoord moet minstens 8 characters bevatten waarvan minimaal één kleine letter een hoodfletter, één cijfer en één speciaal teken (# ? ! @ $ % ^ & * -).",
            required: true,
            autocomplete: "new-password"
        }]
    }
}