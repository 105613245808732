import {API_URL, types} from "../action.constants";
import axios from "axios";
import {errorHelper} from "../../helpers/message.helpers/error.message.helpers";
import snackbarFactories from "../../factories/snackbar.factories";

export const loadMedia = (id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {media} = state.mediaReducer;

    const obj = media.find(x => x.id === id);
    if (!obj) {
        dispatch({type: types.MEDIA_LOAD, payload: {media: null, id}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/images/${id}/data`, header)
            .then(({data}) => {
                dispatch({type: types.MEDIA_LOAD, payload: {media: data, id}});
            })
            .catch(error => {
                const response = error.response;
                const message = errorHelper('image', 'load', response, error.response.status);
                dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: message, type: 'error'})}});
            });
    }
};