import {API_URL, types} from "../action.constants";
import axios from "axios";
import snackbarFactories from "../../factories/snackbar.factories";

export const loginUser = body => dispatch => {
    dispatch({type: types.USER_LOGIN, payload: {user: null, token: null, userLoading: true}});
    const header = {headers: {'x-app-coce': '1'}};
    axios.post(`${API_URL}/login`, body, header)
        .then(({data}) => {
            const {userId, token} = data;

            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('apiToken', token);
            dispatch({type: types.USER_LOGIN, payload: {user: null, token, userLoading: true}});
            loadProfile(userId, token, dispatch)
        })
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: types.USER_LOGOUT});
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: message, type: 'error'})}});
        });
};

export const logoutUser = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': `Bearer ${token}`, 'x-app-coce': '1'}};
    axios.get(`${API_URL}/logout`, header)
        .then(() => {
            dispatch({type: types.USER_LOGOUT});
            clearSessionStorage();
        });
};

export const loadUser = () => dispatch => {
    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('apiToken');

    if (userId && token) {
        dispatch({type: types.USER_LOGIN, payload: {user: null, token, userLoading: true}});
        loadProfile(userId, token, dispatch);
    }
};

export const activateUser = (body) => dispatch => {
    dispatch({type: types.USER_LOGIN, payload: {userId: null, token: null, userLoading: true}});
    axios.post(API_URL + '/register', body)
        .then(({data}) => {
            const {userId, token} = data;

            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('apiToken', token);
            dispatch({type: types.USER_LOGIN, payload: {user: null, token, userLoading: true}});
            loadProfile(userId, token, dispatch);
        })
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: types.USER_LOGOUT});
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: message, type: 'error'})}});
        });
};

export const updateUser = (body) => (dispatch, getState) => {
    const state = getState();
    const {token, user} = state.authReducer;

    const header = {headers: {'x-app-coce': '1', 'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/users/${user.id}`, body, header)
        .then(({data}) => {
            dispatch({type: types.USER_UPDATE, payload: {user: data}});
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: "Je gegevens werden succesvol gewijzigd", type: 'success'})}})
        })
        .catch(error => {
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: "Het wijzigen van je gegevens is mislukt vanwege een onverwachte fout.", type: 'error'})}})
        });
};

export const removeImage = () => (dispatch, getState) => {
    const state = getState();
    const {token, user} = state.authReducer;

    const header = {headers: {'x-app-coce': '1', 'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/users/${user.id}/remove-image`, {}, header)
        .then(({data}) => {
            dispatch({type: types.USER_UPDATE, payload: {user: data}});
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: "Je gegevens werden succesvol gewijzigd", type: 'success'})}})
        })
        .catch(error => {
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: "Het wijzigen van je gegevens is mislukt vanwege een onverwachte fout.", type: 'error'})}})
        });
}

export const resetUser = (data, navigate) => dispatch => {
    axios.post(API_URL + '/users/reset', data)
        .then(() => {
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: 'Het account is hersteld. Zoek in je mailbox naar de activatie-mail.', type: 'success'})}});
            navigate('/login');
        })
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: message, type: 'error'})}});
        });
}

const loadProfile = (userId, token, dispatch) => {
    const header = {headers: {'Authorization': `Bearer ${token}`, 'x-app-coce': '1'}};
    axios.get(`${API_URL}/users/${userId}`, header)
        .then(({data}) => {
            const darkThemeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
            const mode = data.settings.theme;

            dispatch({type: types.USER_LOAD, payload: {user: data, userLoading: false}});
            dispatch({type: types.THEME_MODE_UPDATE, payload: {mode}});
            dispatch({type: types.THEME_UPDATE, payload: {theme: mode === 'auto' ? (darkThemeMediaQuery.matches ? 'dark' : 'light') : mode}});
        })
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: types.USER_LOGOUT});
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: message, type: 'error'})}});
        });
};


const handleErrors = (error) => {
    if (error.response && error.response.status) {
        if (error.response.status === 401 || error.response.status === 422) {
            return "Het e-mailadres en wachtwoord komen niet overeen.";
        }

        if (error.response.status && error.response.status === 404) {
            return "Gegevens niet correct. Volg de link uit de e-mail die je van ons kreeg.";
        }
    }
    return "Inloggen mislukt vanwege een onverwachte fout.";
};


const clearSessionStorage = () => {
    sessionStorage.clear();
};