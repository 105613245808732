export const types = {
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_LOAD: 'USER_LOAD',
    USER_UPDATE: 'USER_UPDATE',

    TRAININGS_LOAD: 'TRAININGS_LOAD',
    TRAINING_LOAD: 'TRAINING_LOAD',
    TRAINING_ORDER_LOAD: 'TRAINING_ORDER_LOAD',

    MEDIA_LOAD: 'MEDIA_LOAD',
    ATTACHMENTS_LOAD: 'ATTACHMENTS_LOAD',
    TOPPINGS_LOAD: 'TOPPINGS_LOAD',

    TOAST_ADD: 'TOAST_ADD',
    TOAST_REMOVE: 'TOAST_REMOVE',
    QUESTIONS_LOAD: 'QUESTIONS_LOAD',

    THEME_UPDATE: 'THEME_UPDATE',
    THEME_MODE_UPDATE: 'THEME_MODE_UPDATE'
}

export const API_URL = process.env.REACT_APP_API_URL;