import React from "react";
import {Navigate, useLocation} from "react-router-dom";

const PrivateRoute = ({children, loggedIn}) => {
    let location = useLocation();

    return loggedIn
        ? children
        : <Navigate to="/login" state={{from: location}} />
};

export default PrivateRoute