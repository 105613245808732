import {PillsBar, PillsContent} from "../../../components/Pills";
import {useState} from "react";
import {Biography, Interview, Picture} from "./ProfileComponents";
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../../../core/store/authentication/auth.actions";
import "./Profile.scss";

const tabs = [
    {name: "bio", label: "Biografie"},
    {name: "interview", label: "Interview"},
    {name: "picture", label: "Afbeelding"},
];

const Profile = () => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(tabs[0].name);
    const {user} = useSelector(state => state.authReducer);

    const handleSubmit = (body) => {
        dispatch(updateUser(body));
    };

    return (
        <div className="account">
            <PillsBar tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
            <PillsContent selectedTab={selectedTab}>
                <Biography name="bio"
                           user={user}
                           biography={user.biography}
                           submit={handleSubmit}
                />
                <Interview name="interview"
                           user={user}
                           answers={user.answers}
                           submit={handleSubmit}
                />
                <Picture name="picture"
                         user={user}
                         picture={user.picture}
                         submit={handleSubmit}
                />
            </PillsContent>
        </div>
    )
}

export default Profile;