import {types} from "../action.constants";

let defaultState = {
    toppings: [],
    toppingsLoading: false,
};

const loadToppings = (state, {toppings, toppingsLoading}) => ({
    ...state, toppings, toppingsLoading
});

export const toppingReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case types.TOPPINGS_LOAD:
            return loadToppings(state, payload);
        default:
            return state;
    }
};
