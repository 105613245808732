import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Toast from "./Toast";
import {removeToast} from "../../core/store/snackbar/snackbar.actions";

const Snackbar = () => {
    const dispatch = useDispatch();
    const {toasts} = useSelector(state => state.snackbarReducer);

    return (
        <div className="toasts">
            {toasts.map(toast => {
                const {id} = toast;

                return (
                    <Toast {...toast} key={id} onDismissClick={() => dispatch(removeToast(id))}/>
                );
            })}
        </div>
    );
};


export default Snackbar;