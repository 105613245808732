import React from "react";
import PropTypes from "prop-types";

export const Container = (props) => {
    let string = ['container'];
    props.className && string.push(props.className);

    return <section className={string.join(' ')} style={props.style}>{props.children}</section>
};

Container.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}