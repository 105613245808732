import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeMode, changeTheme} from "../../store/application/application.actions";

export const useDarkMode = () => {
    const dispatch = useDispatch();
    const {theme, mode} = useSelector(state => state.applicationReducer);

    const updateTheme = () => {
        let newMode;

        switch (mode) {
            case 'light':
                newMode = 'dark';
                break;

            case 'auto':
                newMode = 'light';
                break;

            case 'dark':
            default:
                newMode = 'auto';
                break;
        }

        dispatch(changeMode(newMode));
    }

    const mediaQueryListener = useCallback((e)=>{
        if (mode === 'auto')
            dispatch(changeTheme(e.matches ? 'dark' : 'light'));
        else
            dispatch(changeTheme(mode));

    },[mode, dispatch])
    useEffect(()=>{
        const darkThemeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        darkThemeMediaQuery.addEventListener('change', mediaQueryListener);
    },[mode, mediaQueryListener])

    return [theme, mode, updateTheme]
}