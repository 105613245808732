import {combineReducers} from "redux";
import {authReducer} from "./authentication/auth.reducers";
import {snackbarReducer} from "./snackbar/snackbar.reducers"
import {applicationReducer} from "./application/application.reducers";
import {trainingReducer} from "./training/training.reducers";
import {mediaReducer} from "./media/media.reducers";
import {attachmentReducer} from "./attachment/attachment.reducer";
import {toppingReducer} from "./topping/topping.reducers";

export default combineReducers({
    authReducer,
    applicationReducer,
    mediaReducer,
    attachmentReducer,
    trainingReducer,
    toppingReducer,
    snackbarReducer,
});