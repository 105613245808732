let id = 0;

const defaultOptions = {
};

export default function snackbarFactories(options) {
    id++;

    return {
        ...defaultOptions,
        ...options,
        id
    };
}