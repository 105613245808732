import {actionHelper, typeHelper} from "./helperComponents";


export const errorHelper = (type, action, response, status) => {
    if (response) {
        if (status && status === 401) {
            return `Je bent niet bevoegd om ${typeHelper(type, true).toLowerCase()} ${actionHelper(action)}.`;
        }
        if (status && status === 403) {
            return `Je hebt niet voldoende rechten om ${typeHelper(type, true).toLowerCase()} ${actionHelper(action)}.`;
        }
        if (status && status === 404) {
            return `${typeHelper(type)} niet gevonden.`;
        }
    }
    return `${typeHelper(type)} ${helper(action)} mislukt vanwege een onverwachte fout.`;
}

const helper = action => {
    switch (action) {
        case 'create': return 'aanmaken';
        case 'update': return 'wijzigen';
        case 'delete': return 'verwijderen';
        case 'publish': return 'publiseren';
        case 'pin': return 'vastpinnen';
        case 'post': return 'versturen';
        default: return 'laden';
    }
}