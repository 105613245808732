import {types} from "../action.constants";

const darkThemeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
let defaultState = {
    questions: [],
    questionsLoading: false,
    mode: 'auto',
    theme: darkThemeMediaQuery.matches ? 'dark' : 'light',
};

const changeTheme = (state, {theme}) => ({...state, theme});
const changeMode = (state, {mode}) => ({...state, mode});

const loadQuestions = (state, {questions, questionsLoading}) => ({
    ...state, questions, questionsLoading
})

export const applicationReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case types.THEME_UPDATE:
            return changeTheme(state, payload);
        case types.THEME_MODE_UPDATE:
            return changeMode(state, payload);
        case types.QUESTIONS_LOAD:
            return loadQuestions(state, payload);
        default:
            return state;
    }
};

export default applicationReducer;