import React from "react";
import {BrowserRouter} from "react-router-dom";
import SiteRouter from "./SiteRouter";
import {useDarkMode} from "./core/hooks/useDarkMode/useDarkMode";

import "./assets/scss/app.scss"

const App = () => {
    const [theme] = useDarkMode();

    return (
        <div className={`base theme--${theme}`}>
            <BrowserRouter>
                <SiteRouter/>
            </BrowserRouter>
        </div>
    );
};

export default App;
