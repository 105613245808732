import {types} from "../action.constants";

let defaultState = {
    media: [],
};

const loadMedia = (state, {media, id}) => {
    const search = state.media.find(x => x.id === id);

    let temp = [];
    if (search) {
        temp = state.media.map(x => {
            if (x.id !== id) return x;

            return {
                id,
                ...media,
                loading: !media
            };
        });
    } else {
        temp = [
            ...state.media,
            {
                id,
                ...media,
                loading: !media
            }
        ];
    }

    return ({...state, media: temp})
}

export const mediaReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case types.MEDIA_LOAD:
            return loadMedia(state, payload);
        default:
            return state;
    }
};