import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedin, faTwitter} from "@fortawesome/free-brands-svg-icons";

const SocialMenu = () => (
    <div className="socials">
        <a href="https://www.facebook.com/UniPartnersNV"
           target={"_blank"}
           rel="noopener noreferrer"
        >
            <FontAwesomeIcon icon={faFacebookF}
                             fixedWidth={true}
            />
        </a>
        <a href="https://www.instagram.com/unipartners_belgium"
           target={"_blank"}
           rel="noopener noreferrer"
        >
            <FontAwesomeIcon icon={faInstagram}
                             fixedWidth={true}
            />
        </a>
        <a href="https://twitter.com/UniPartnersNV"
           target={"_blank"}
           rel="noopener noreferrer"
        >
            <FontAwesomeIcon icon={faTwitter}
                             fixedWidth={true}
            />
        </a>
        <a href="https://www.linkedin.com/company/unipartnersgroup"
           target={"_blank"}
           rel="noopener noreferrer"
        >
            <FontAwesomeIcon icon={faLinkedin}
                             fixedWidth={true}
            />
        </a>
    </div>
)

export default SocialMenu;