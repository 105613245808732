import React from "react";
import PropTypes from "prop-types";

export const Row = (props) => {
    let string = ['row'];
    props.className && string.push(props.className);

    return <div className={string.join(' ')} style={props.style}>{props.children}</div>
};

Row.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}