import {API_URL, types} from "../action.constants";
import axios from "axios";
import snackbarFactories from "../../factories/snackbar.factories";
import {errorHelper} from "../../helpers/message.helpers/error.message.helpers";

export const changeMode = (mode) => (dispatch, getState) => {
    const state = getState();
    const {token, user} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/users/${user.id}/set-theme`, {theme: mode}, header)
        .then(({data}) => {
            const darkThemeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
            const mode = data.settings.theme;

            dispatch({type: types.USER_LOAD, payload: {user: data, userLoading: false}});
            dispatch({type: types.THEME_MODE_UPDATE, payload: {mode}});
            dispatch({type: types.THEME_UPDATE, payload: {theme: mode === 'auto' ? (darkThemeMediaQuery.matches ? 'dark' : 'light') : mode}});
        })
        .catch(error => {
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: "Het wijzigen van je voorkeuren is mislukt vanwege een onverwachte fout.", type: 'error'})}})
        });
};

export const changeTheme = (theme) => dispatch => {
    dispatch({type: types.THEME_UPDATE, payload: {theme}});
};

export const loadQuestions = () => (dispatch, getState) => {
    dispatch({type: types.QUESTIONS_LOAD, payload: {questions: [], questionsLoading: true}});
    const state = getState();
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(`${API_URL}/questions`, header)
        .then(({data}) => {
            dispatch({type: types.QUESTIONS_LOAD, payload: {questions: data, questionsLoading: false}});
        })
        .catch(error => {
            const response = error.response;
            const message = errorHelper('questions', 'read', response, error.response.status);
            dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: message, type: 'error'})}});
        });
}