import React from 'react';
import PropTypes from "prop-types";

const InputField = ({type, id, name, label, placeholder, description, value, min, max, step, valid, onChange, onBlur, error, className, styles, required, readOnly, autocomplete, disabled}) => (
    <div className={`form-group${className ? ` ${className}` : ''}`} style={styles}>
        {label && <label htmlFor={id}>{label} {required && <code>*</code>}</label>}

        <input type={type}
               name={name}
               id={id}
               value={value ?? null}
               min={min}
               max={max}
               step={step}
               placeholder={(placeholder) + (placeholder && required && !label ? " *" : "")}
               className={`form-control${valid === '' ? '' : (valid ? ' is-valid' : ' is-invalid')}`}
               onChange={onChange}
               onBlur={onBlur}
               autoComplete={typeof autocomplete === "boolean" ? (autocomplete ? "on" : "off") : autocomplete}
               readOnly={readOnly}
               disabled={disabled}
        />
        <div className="description">{description}</div>
        <small className="invalid-feedback">{error}</small>
    </div>
);


InputField.defaultProps = {
    label: '',
    placeholder: '',
    value: '',
    error: '',
    valid: true,
    className: '',
    styles: {},
    required: false,
    readOnly: false,
    autocomplete: false,
    disabled: false,
};


InputField.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    valid: PropTypes.bool,
    className: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    styles: PropTypes.object,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    autocomplete: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
};


export default InputField;