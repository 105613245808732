import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import React from "react";
import {TrainingDetail, TrainingList} from "../../views/Trainings";
import {Account, Profile} from "../../views/User";

const LayoutRouter = () => {
    return (
        <Routes>
            <Route path="opleidingen" element={<Outlet/>}>
                <Route index element={<TrainingList/>}/>
                <Route path=":slug" element={<TrainingDetail/>}/>
            </Route>
            <Route exact path="/profiel" element={<Profile/>}/>
            <Route exact path="/account" element={<Account/>}/>

            <Route exact path="/" element={<Navigate to="/opleidingen"/>}/>
            <Route path="*" element={<Navigate to="/opleidingen"/>}/>
        </Routes>
    )
}

export default LayoutRouter