import React, {useEffect} from "react";
import {Route, Router, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loadUser} from "./core/store/authentication/auth.actions";
import {Activate, ForgotPass, Login} from "./views/Authentication";
import Layout from "./components/Layout/Layout";
import Loading from "./components/Loading/Loading";
import PrivateRoute from "./views/Authentication/PrivateRoute";
import PublicRoute from "./views/Authentication/PublicRoute";
import NotFound from "./views/NotFound/NotFound";

const SiteRouter = () => {
    const dispatch = useDispatch();
    const {loggedIn, userLoading} = useSelector(state => state.authReducer);

    useEffect(() => {
        dispatch(loadUser())
    }, [dispatch]);

    return (
        <Routes>
            <Route path="/*" element={
                <PrivateRoute loggedIn={loggedIn}>
                    {userLoading
                        ? <Loading/>
                        : <Layout/>
                    }
                </PrivateRoute>
            }/>

            <Route path="/login" element={(
                <PublicRoute loggedIn={loggedIn} useFrom={true}>
                    <Login type="login"/>
                </PublicRoute>
            )}/>

            <Route path="/activeer" element={
                <PublicRoute loggedIn={loggedIn} useFrom={false}>
                    <Activate type="activate"/>
                </PublicRoute>
            }/>

            <Route path="/wachtwoord-vergeten" element={
                <PublicRoute loggedIn={loggedIn} useFrom={false}>
                    <ForgotPass type="forgotPass"/>
                </PublicRoute>
            }/>
        </Routes>
    )
}

export default SiteRouter;