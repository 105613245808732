import React from "react";
import {Container} from "../../Grid/Grid";
import "./CrownBar.scss";
import SocialMenu from "./SocialMenu/SocialMenu";
import {useDarkMode} from "../../../core/hooks/useDarkMode/useDarkMode";
import Toggle from "../../Theme/Toggle";

const CrownBar = () => {
    const [theme, mode, toggleTheme] = useDarkMode();

    return (
        <div className={`crownBar`}>
            <Container>
                <div>
                    <SocialMenu/>
                </div>

                <Toggle theme={theme} mode={mode} toggleTheme={toggleTheme}/>
            </Container>
        </div>
    );
}

export default CrownBar;
