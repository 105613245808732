import {InputField} from "../../../../components/Form/formInput";
import {useState} from "react";
import Button from "../../../../components/Button/Button";
import {FieldValidator} from "../../../../core/helpers/form.helpers";

const Password = ({user, submit}) => {
    const [password, setPassword] = useState({type: 'password', required: true, checkRequirements: true, name: 'email', value: '', valid: true, error: ''});

    const handleChange = (e) => {
        const {value} = e.target;

        setPassword({
            ...password,
            ...FieldValidator.validate(password, value),
            value: value,
        });
    }

    const cancelForm = () => {
        setPassword({type: 'password', required: true, name: 'email', value: '', valid: true, error: ''})
    }

    const handleSubmit = () => {
        submit({password: password.value});
    }

    return (
        <>
            <h1>{`${user.firstName} ${user.lastName}`}<br /><div>Wijzig wachtwoord</div></h1>

            <InputField type="password"
                        name="password"
                        id="password"
                        label="Wachtwoord"
                        value={password.value}
                        description="Het wachtwoord moet minstens 8 characters bevatten waarvan minimaal één kleine letter een hoodfletter, één cijfer en één speciaal teken (# ? ! @ $ % ^ & * -)."
                        required={true}
                        valid={password.valid}
                        error={password.error}
                        onChange={handleChange}
                        autocomplete="new-password"
            />

            <div className="right">
                <div className="btn-group right">
                    <Button buttonStyle="secondary"
                            label="Annuleren"
                            onClick={cancelForm}
                    />
                    <Button buttonStyle="primary"
                            label="Bewaren"
                            disabled={!password.valid || password.value.length === 0}
                            onClick={handleSubmit}
                    />
                </div>
            </div>
        </>
    );
}

export default Password;