import {Row, Col} from "../Grid/Grid";
import {toShortDate} from "../../core/helpers/date.helpers";
import ItemOverview from "../ItemOverview/ItemOverview";
import BackButton from "../BackButton/BackButton";
import Carrousel from "../Carrousel/Carrousel";
import Attachments from "../Attachments/Attachments";
import "./ItemDetail.scss";
import PropTypes from "prop-types";
import Subscription from "../Subscription/Subscription";


const ItemDetail = ({obj, objType, title, body, media, attachments, links, coce, tags, author, publishDate, instructor, organisors, days, location}) => {
    return (
        <Row>
            <Col xsOrder={1} md={12}>
                {media && media.length !== 0 &&
                <Carrousel objMedia={media}/>}

                <div className={"author"}>
                    Geplaatst door {author.firstName} {author.lastName} op {toShortDate(publishDate)}
                </div>
            </Col>

            <Col lg={8} xl={9} xsOrder={3} lgOrder={2}>
                <h1>{title}</h1>
                <div className={"item-body"} dangerouslySetInnerHTML={{__html: body}}/>

                {((tags && tags.length > 0) || coce) &&
                (<div className={"tags"}>
                    <ul>
                        {coce &&
                        <li>#{coce.name}</li>
                        }
                        {tags && tags.length > 0 && tags.map((tag, i) => (
                            <li key={i}>#{tag}</li>
                        ))}
                    </ul>
                </div>)
                }
            </Col>

            <Col lg={4} xl={3} xsOrder={2} lgOrder={3}>
                <div className={"detail-sidebar"}>
                    <Subscription author={author}
                                  obj={obj}
                                  objType={objType}
                    />

                    {(location || (days && days.length > 0) || instructor || (organisors && organisors.length > 0)) &&
                    <ItemOverview location={location}
                                  days={days}
                                  instructor={instructor}
                                  organisers={organisors}
                    />
                    }

                    {((attachments && attachments.length > 0) || (links && links.length > 0)) &&
                    <Attachments attachments={attachments} links={links}/>}

                    <BackButton/>
                </div>
            </Col>
        </Row>
    );
}

ItemDetail.propTypes = {
    obj: PropTypes.object.isRequired,
    objType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    media: PropTypes.array,
    attachments: PropTypes.array,
    links: PropTypes.array,
    coce: PropTypes.object,
    tags: PropTypes.array,
    author: PropTypes.object.isRequired,
    publishDate: PropTypes.number,
    location: PropTypes.string,
    days: PropTypes.array,
    instructor: PropTypes.object,
    organisers: PropTypes.array,
}

export default ItemDetail;