import {API_URL, types} from "../action.constants";
import axios from "axios";
import {errorHelper} from "../../helpers/message.helpers/error.message.helpers";
import snackbarFactories from "../../factories/snackbar.factories";

export const downloadAttachment = (id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {attachments} = state.attachmentReducer;

    const obj = attachments.find(x => x.id === id);
    if (obj) {
        download(obj);
    } else {
        dispatch({type: types.ATTACHMENTS_LOAD, payload: {blob: null, id: id}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/attachments/${id}/data`, header)
            .then(({data}) => {
                dispatch({type: types.ATTACHMENTS_LOAD, payload: {attachment: data, id: id}});
                download(data);
            })
            .catch(error => {
                const response = error.response;
                const message = errorHelper('attachment', 'load', response, error.response.status);
                dispatch({type: types.TOAST_ADD, payload: {toast: snackbarFactories({text: message, type: 'error'})}});
            });
    }
};

const download = (data) => {
    let a = document.createElement('a');
    a.href = data.file;
    a.download = data.filename;
    a.click();
}