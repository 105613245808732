import React from "react";
import {Container} from "../../Grid/Grid";
import Logo from "../../../assets/images/logo-up.png";
import {Link} from "react-router-dom";
import MainNavigation from "./MainNavigation/MainNavigation";
import './Navigation.scss'

const Navigation = () => {
    return (
        <header className={"header"}>
            <Container>
                <Link to={"/home"}><img src={Logo} alt={"UniPartners, united around values"} className={"logo"}/></Link>

                <nav>
                    <MainNavigation/>
                </nav>

            </Container>
        </header>
    );
}


export default Navigation;