export const INPUT_TYPES = {
    COL: "col",
    GROUP: "group",
    TAB: "tab",

    TEXT: "text",
    EMAIL: "email",
    PASSWORD: "password",
    NUMBER: "number",
    FILE: "file",
    URL: "url",
    CHECKBOX: "checkbox",
    RADIO: "radio",
    HIDDEN: "hidden",
    TEXTAREA: "textarea",
    SELECT: "select",
    CREATABLE: "creatable",
    TINY: "tiny",
    DROPZONE: "dropzone",
    DATEPICKER: "datepicker",
    TIMERANGE: "timerange",
};