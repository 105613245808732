import {breadSizeHelper} from "../../helpers/order.helpers";

export const orderData = (days, order, toppings) => {
    return {
        days: days.map(x => {
            let day = null, top = null;
            if (order) {
                day = order.days.find(y => y.date === x.startDate);
                top = day ? toppings.find(y => y.id === day.topping) : null;
            }

            return {
                date: x.startDate,
                acceptOrders: !!x.bread,
                reason: x.reason,
                order: day ? !!(day && top) : true,
                size: day && top ? {value: day.size, label: breadSizeHelper(day.size)} : null,
                sizeTouched: false,
                sizeValid: day ? day && top : !x.bread,
                topping: day && top ? {value: day.topping, label: top.topping, extra: top.hasOptions, desc: top.description} : null,
                toppingTouched: false,
                toppingValid: day ? day && top : !x.bread,
                extra: day && top ? (day.extra ?? false) : false,
            }
        }),
        updated: false,
        remark: order && order.remark && order.remark !== 'null' ? order.remark : ''
    }
}