import {useState} from "react";
import {Col, Row} from "../../../../components/Grid/Grid";
import InputTextarea from "../../../../components/Form/formInput/InputTextarea";
import Button from "../../../../components/Button/Button";

const rowSettings = {
    rows: 5,
    minRows: 5,
    maxRows: 15,
};

const Biography = ({user, biography, submit}) => {
    const [value, setValue] = useState(biography);
    const [rows, setRows] = useState(rowSettings.rows)

    const handleChange = (e) => {
        const textareaLineHeight = 22;
        const {minRows, maxRows} = rowSettings;
        const {value} = e.target;

        const previousRows = e.target.rows;
        e.target.rows = minRows;

        const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            e.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            e.target.rows = maxRows;
            e.target.scrollTop = e.target.scrollHeight;
        }

        setValue(value);
        setRows(currentRows < maxRows ? currentRows : maxRows)
    };

    const cancelForm = () => {
        setRows(rowSettings.rows);
        setValue(biography);
    };

    const handleSubmit = () => {
        submit({biography: value});
    };

    return (
        <>
            <h1>{`${user.firstName} ${user.lastName}`}<br /><div>Biografie</div></h1>

            <Row>
                <Col md={12} className="bio">
                    <InputTextarea name="biography"
                                   id="biography"
                                   label="Biografie"
                                   rows={rows}
                                   value={value}
                                   required={false}
                                   onChange={handleChange}
                    />
                </Col>

                <Col md={12} className="right">
                    <div className="btn-group right">
                        <Button buttonStyle="secondary"
                                label="Annuleren"
                                onClick={cancelForm}
                        />
                        <Button buttonStyle="primary"
                                label="Bewaren"
                                onClick={handleSubmit}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Biography;