export const actionHelper = (action) => {
    switch (action) {
        case 'create': return 'aan te maken';
        case 'created': return 'aangemaakt';
        case 'read': return 'te bekijken';
        case 'load': return 'te laden';
        case 'update': return 'te wijzigen';
        case 'updated': return 'gewijzigd';
        case 'delete': return 'te verwijderen';
        case 'deleted': return 'verwijderd';
        case 'publish': return 'te publiseren';
        case 'published': return 'gepubliseerd';
        case 'pin': return 'vast te pinnen';
        case 'pinned': return 'vastgepind';
        case 'unpinned': return 'los gemaakt';
        case 'post': return 'te versturen';
        default: return 'te doen';
    }
}