import {types} from "../action.constants";

let defaultState = {
    attachments: [],
};

const loadAttachment = (state, {attachment, id}) => {
    const search = state.attachments.find(x => x.id === id);

    let temp;
    if (search) {
        temp = state.attachments.map(x => {
            if (x.id !== id) return x;

            return {
                id,
                ...attachment,
                loading: !attachment
            };
        });
    } else {
        temp = [
            ...state.attachments,
            {
                id,
                ...attachment,
                loading: !attachment
            }
        ];
    }

    return ({...state, attachments: temp})
}

export const attachmentReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case types.ATTACHMENTS_LOAD:
            return loadAttachment(state, payload);
        default:
            return state;
    }
};