import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import {getFileIcon} from "../../core/helpers/file.helpers";
import Button from "../Button/Button";
import {Link} from "react-router-dom";
import "./Attachments.scss";
import {useDispatch} from "react-redux";
import {downloadAttachment} from "../../core/store/attachment/attachment.action";

const Attachments = ({links, attachments}) => {
    const dispatch = useDispatch();

    return (
        <div className={"attachments"}>
            <span>Bijlagen</span>

            {links && links.length > 0 && links.map((link, i) => (
                <div className={"attachment"} key={`l-${link.id}`}>
                    <span>
                        <Link to={link.url}
                              target="_blank"
                              className="btn btn-link"
                              rel="nofollow noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                            {link.name}
                        </Link>
                    </span>
                </div>
            ))}

            {attachments && attachments.length > 0 && attachments.map((attachment, i) => {
                let icon = getFileIcon(attachment.mime);

                return (
                    <div className={"attachment"} key={`a-${attachment.id}`}>
                        <span>
                            <Button type="button"
                                    buttonStyle="link"
                                    onClick={() => dispatch(downloadAttachment(attachment.id))}
                            >
                                <FontAwesomeIcon icon={icon} fixedWidth/>
                                {attachment.description}
                            </Button>
                        </span>
                    </div>
                )
            })}
        </div>
    );
}

export default Attachments;