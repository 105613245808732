import {types} from "../action.constants";

let defaultState = {
    trainings: [],
    trainingsLoading: false,
    training: null,
};

const loadTrainings = (state, {trainings, trainingsLoading}) => ({
    ...state, trainings, trainingsLoading
});

const loadTraining = (state, {training, trainingsLoading}) => ({
    ...state,
    training,
    trainingsLoading,
    trainings: state.trainings.map(t => {
        if (!training || t.id !== training.id) return t;

        return {
            ...t,
            ...training,
            isComplete: !!training
        }
    })
});

const loadOrder = (state, {order, id}) => {
    return {
        ...state,
        trainings: state.trainings.map(x => {
            if (x.id !== id) return x;

            return {
                ...x,
                order
            }
        }),
        training: {
            ...state.training,
            order
        }
    }
};

export const trainingReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case types.TRAININGS_LOAD:
            return loadTrainings(state, payload);
        case types.TRAINING_LOAD:
            return loadTraining(state, payload);
        case types.TRAINING_ORDER_LOAD:
            return loadOrder(state, payload);
        default:
            return state;
    }
};
