import React from "react";
import {Container} from "../Grid/Grid";
import Toasts from "../Snackbar/Snackbar";
import {CrownBar, Navigation} from "../Header";
import LayoutRouter from "./LayoutRouter";

const Layout = () => {
    return (
        <>
            <CrownBar/>
            <Navigation/>

            <main>
                <Container>
                    <LayoutRouter/>
                </Container>
            </main>

            <Toasts/>
        </>
    );
}

export default Layout;