import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import Loading from "../Loading/Loading";
import {defaultSrc} from "../../core/helpers/image.helpers";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {loadMedia} from "../../core/store/media/media.actions";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carrousel.scss";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>
};

const Carrousel = ({objMedia}) => {
    const dispatch = useDispatch();
    const {media} = useSelector(state => state.mediaReducer);

    useEffect(() => {
        objMedia.map(m => dispatch(loadMedia(m.id)));
    }, [dispatch, objMedia]);

    return (
        <Slider {...settings}>
            {objMedia.map((obj, i) => {
                const image = media.find(x => x.id === obj.id);
                if (image) {
                    return (
                        <div key={i}>
                            {image.loading
                                ? <Loading/>
                                : <img src={image.file} alt={image.description} style={{width: '200px'}} onError={defaultSrc}/>
                            }
                        </div>
                    )
                }
                return null;
            })}
        </Slider>
    );
}

function PrevArrow(props) {
    const {onClick} = props;
    return (
        <div className={"slick-arrows prev"} onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft}/>
        </div>
    );
}

function NextArrow(props) {
    const {onClick} = props;
    return (
        <div className={"slick-arrows next"} onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight}/>
        </div>
    );
}

Carrousel.propTypes = {
    objMedia: PropTypes.array.isRequired,
}

export default Carrousel;